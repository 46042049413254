import React from 'react';
import { useRouter } from 'next/router';
import {
  Button as MuiButton,
  Popper,
  Grow,
  ClickAwayListener,
  Paper as MuiPaper,
  List as MuiList,
  ListItem as MuiListItem,
  ListItemButton as MuiListItemButton,
  ListItemText as MuiListItemText,
  Divider as MuiDivider,
  DividerProps
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useElementSize } from 'usehooks-ts';
import ErrorBoundary from 'sensortower-components/src/base-components/ErrorBoundary';

import { sidekick } from '../../utils/sidekick';

export interface LanguageOption {
  label: string;
  value: string;
  disabled?: boolean;
}

export interface LanguageSelectorProps {
  __typename?: string;
  items?: LanguageOption[];
  sidekickLookup: any;
}

export const LanguageSelector = ({ items, sidekickLookup }: LanguageSelectorProps) => {
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { width } = useElementSize(anchorRef);

  // Get current path without locale
  const { asPath, locale, defaultLocale } = useRouter();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setAnchorEl(null);
  };

  const selected = items?.find((o) => o?.value?.toLowerCase() === locale?.toLowerCase());
  const open = Boolean(anchorEl);

  return (
    <ErrorBoundary {...sidekick(sidekickLookup)}>
      <Button ref={anchorRef} variant="language" onClick={handleClick} data-testid="LanguageSelector-button">
        {selected?.label}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="top"
        transition
        style={{ zIndex: 2000 }}
        placeholder=""
        onPointerEnterCapture={() => {}}
        onPointerLeaveCapture={() => {}}>
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} timeout={350} style={{ transformOrigin: 'bottom' }}>
            <div>
              <ClickAwayListener onClickAway={handleClose}>
                <Paper elevation={24} sx={{ width }}>
                  <List>
                    {items?.map((lang) => (
                      <React.Fragment key={lang.value}>
                        <ListItem disablePadding>
                          <ListItemButton
                            selected={selected?.value === lang.value}
                            disabled={lang.disabled}
                            data-testid="LanguageSelector-itemButton"
                            onClick={() => {
                              // Do full page reload to fix issues with links after language change
                              // We don't want to add the defaultLocale to the URL since it will be removed by the router
                              window.location.href = `${window.location.origin}${
                                lang.value === defaultLocale ? '' : '/' + lang.value
                              }${asPath}${window.location.search}`;

                              // Hide the language selector
                              setAnchorEl(null);
                            }}>
                            <ListItemText primary={lang.label} data-testid="LanguageSelector-itemText" />
                          </ListItemButton>
                        </ListItem>
                        <Divider variant="middle" component="li" />
                      </React.Fragment>
                    ))}
                  </List>
                </Paper>
              </ClickAwayListener>
            </div>
          </Grow>
        )}
      </Popper>
    </ErrorBoundary>
  );
};

export default LanguageSelector;

const Button = styled(MuiButton, {
  name: 'LanguageSelector',
  slot: 'root'
})<{ variant?: string }>(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: 'auto',
    minWidth: 120,
    height: 'auto',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    textTransform: 'uppercase'
  },
  [theme.breakpoints.down('sm')]: {
    padding: '3px 12px',
    fontSize: 10,
    letterSpacing: '1.2px'
  }
}));

const Paper = styled(MuiPaper, {
  name: 'LanguageSelector',
  slot: 'menu'
})<{ variant?: string }>(({ theme }) => ({
  width: 240,
  marginBottom: theme.spacing(1),
  borderBottomRightRadius: 0,
  borderBottomLeftRadius: 0,
  borderTopRightRadius: 25,
  borderTopLeftRadius: 25
}));

const List = styled(MuiList, {
  name: 'LanguageSelector',
  slot: 'menuList'
})<{ variant?: string }>(() => ({
  '& .MuiListItem-root:nth-of-type(1)': {
    'paddingTop': 0,
    '& .MuiListItemButton-root': {
      borderTopRightRadius: 25,
      borderTopLeftRadius: 25
    }
  }
}));

const ListItem = styled(MuiListItem, {
  name: 'LanguageSelector',
  slot: 'menuListItem'
})<{ variant?: string }>(({ theme }) => ({
  '& :hover': {
    '& .MuiListItemText-primary': {
      color: theme.palette.grey['50']
    }
  }
}));

const ListItemButton = styled(MuiListItemButton, {
  name: 'LanguageSelector',
  slot: 'menuListItemButton'
})<{ variant?: string }>(({ theme }) => ({
  'height': 50,
  '&:hover': {
    backgroundColor: theme.palette.action.selected
  },
  [theme.breakpoints.down('md')]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  },
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  '&.Mui-selected': {
    'backgroundColor': theme.palette.action.selected,
    '& .MuiListItemText-primary': {
      color: theme.palette.grey['50']
    },
    '&:hover': {
      backgroundColor: theme.palette.action.selected
    }
  }
}));

const ListItemText = styled(MuiListItemText, {
  name: 'LanguageSelector',
  slot: 'menuListItemText'
})<{ variant?: string }>(({ theme }) => ({
  '& .MuiListItemText-primary': {
    textAlign: 'center',
    color: theme.palette.grey['200'],
    fontSize: 12,
    fontWeight: 600,
    letterSpacing: '0.2rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: 10
    }
  }
}));

const Divider = styled(MuiDivider, {
  name: 'LanguageSelector',
  slot: 'divider'
})<DividerProps<React.ElementType>>(({ theme }) => ({
  borderColor: theme.palette.grey['100']
}));
