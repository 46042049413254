import React, { useEffect, useState } from 'react';
import { Container as MuiContainer, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import ErrorBoundary from 'sensortower-components/src/base-components/ErrorBoundary';
import ContentModule from 'sensortower-components/src/base-components/ContentModule';
import Text, { RichText } from 'sensortower-components/src/base-components/Text';
import LanguageSelector, { LanguageSelectorProps } from '../LanguageSelector/LanguageSelector';
import { useLocalizationContext } from '../LocalizationContext';
import { CollectionProps } from '../../stories/Collection/Collection.props';
import { sidekick } from '../../utils/sidekick';

export interface FooterProps {
  __typename?: string;
  languageSelector?: LanguageSelectorProps;
  body?: RichText;
  navigationItems: (CollectionProps & { id: string })[];
  sidekickLookup: any;
}

// We're legally required to display the ICP license number in the footer of the website in China.
// See https://help.aliyun.com/zh/icp-filing/support/website-to-add-the-record-number-faq?spm=a2c4g.11186623.0.0.49375f96sXgQw7#section-s3c-gec-37t.

// This is different than the check in packages/web/middleware.ts, because our
// ICP license is only valid for sensortower.cn domain, so we should only show it there.
const isChinaDomain = (domain: string) => {
  const regexps = [
    /\.?sensortower\.cn$/ // production domain
  ];
  return regexps.some((regexp) => domain.match(regexp));
};

const ChinaICPLink = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Run this check only on the client side
    if (isChinaDomain(window.location.hostname)) {
      setIsVisible(true);
    }
  }, []);

  return isVisible ? (
    <Typography variant="body2" color="textSecondary" component="div">
      <a href="https://beian.miit.gov.cn/" target="_blank">
        京ICP备14050115号-11
      </a>
    </Typography>
  ) : null;
};

export const Footer = ({ languageSelector, body, navigationItems, sidekickLookup }: FooterProps) => {
  const localization = useLocalizationContext();

  const showCookieSidebar = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (window.Osano) {
      window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open');
    }
  };

  return (
    <ErrorBoundary>
      <Root {...sidekick(sidekickLookup)} data-testid="Footer">
        <Container maxWidth="xl">
          <Box display="flex" flexDirection="column" width={1}>
            <Box display="flex" alignItems="flex-start" justifyContent="space-between">
              {languageSelector && (
                <Box flex={1} display="flex">
                  <LanguageSelector {...languageSelector} />
                </Box>
              )}

              {languageSelector && (
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{
                    display: { xs: 'flex', sm: 'none' },
                    ml: 2,
                    backgroundColor: 'common.white'
                  }}
                />
              )}

              <Box flex={3}>
                <Grid container spacing={1} alignItems="center" justifyContent="flex-end">
                  <Grid
                    item
                    container
                    alignItems="center"
                    justifyContent="flex-end"
                    sx={{
                      display: { xs: 'list-item', sm: 'flex' },
                      listStyle: 'none',
                      columns: 2
                    }}>
                    {navigationItems?.map((collection) => (
                      <React.Fragment key={collection.id}>
                        {collection.items?.map((link) => (
                          <Box
                            key={link.id}
                            display="flex"
                            justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}
                            ml={{ xs: 2, sm: 4 }}
                            data-testid="Footer-navigationItem">
                            <ContentModule {...(link as any)} variant="footer" color="inherit" />
                          </Box>
                        ))}
                      </React.Fragment>
                    ))}
                  </Grid>
                  <Grid item sx={{ display: { xs: 'none', sm: 'block' }, textAlign: { xs: 'center', sm: 'right' } }}>
                    {body && <Text body={body} />}
                    <ChinaICPLink />
                    <CookieButton className="osano-show-settings" onClick={showCookieSidebar}>
                      {localization['common.linkTextCookies']?.shortTextValue ?? 'Manage Cookies'}
                    </CookieButton>
                  </Grid>
                </Grid>
              </Box>
            </Box>

            {/* Mobile version */}
            <Box
              sx={{
                display: { xs: 'flex', sm: 'none' },
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: { xs: 2, sm: 0 },
                textAlign: 'right'
              }}>
              {body && <Text body={body} />}
              <ChinaICPLink />
              <CookieButton className="osano-show-settings" onClick={showCookieSidebar}>
                {localization['common.linkTextCookies']?.shortTextValue ?? 'Manage Cookies'}
              </CookieButton>
            </Box>
          </Box>
        </Container>
      </Root>
    </ErrorBoundary>
  );
};

export default Footer;

const Root = styled(Box, {
  name: 'Footer',
  slot: 'root'
})<{ variant?: string }>(({ theme }) => ({
  backgroundColor: theme.palette.text.secondary,
  padding: theme.spacing(5, 0)
}));

const Container = styled(MuiContainer, {
  name: 'Footer',
  slot: 'container'
})<{ variant?: string }>(({ theme }) => ({
  'display': 'flex',
  'alignItems': 'center',
  'justifyContent': 'center',
  '& .MuiTypography-root': {
    color: theme.palette.primary.contrastText,
    paddingBottom: 0,
    fontSize: 10,
    fontWeight: 200,
    [theme.breakpoints.up('sm')]: {
      fontSize: 12
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 15
    }
  },
  '& a': {
    'textDecoration': 'none',
    '& span': {
      fontWeight: 400
    }
  },
  '& hr': {
    borderColor: theme.palette.grey['400']
  }
}));

const CookieButton = styled(Button, {
  name: 'Footer',
  slot: 'CookieButton'
})<{ variant?: string }>(({ theme }) => ({
  // Note: OneTrust settings overridden with !important
  'width': 'auto !important',
  'height': 'auto !important',
  'minWidth': 'auto !important',
  'margin': '0 !important',
  'padding': '0 !important',
  'backgroundColor': 'transparent',
  'border': '0 !important',
  'color': `${theme.palette.primary.contrastText} !important`,
  'fontSize': '10px !important',
  'fontWeight': '200 !important',
  'lineHeight': '1.4 !important',
  'letterSpacing': 'normal',
  'textTransform': 'none',

  [theme.breakpoints.up('sm')]: {
    fontSize: '12px !important'
  },

  [theme.breakpoints.up('lg')]: {
    fontSize: '15px !important'
  },

  '&:hover': {
    backgroundColor: 'transparent !important'
  }
}));
